import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codefresh/volume/bolt/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { useState, useRef, useEffect } from 'react';
import { Playground } from 'docz';
import '../../dist/css/bolt-css.css';
import { Day } from '../../dist/js/bolt';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "day"
    }}>{`Day`}</h1>
    <br />
    <h2 {...{
      "id": "propriedades"
    }}>{`Propriedades`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`export interface DayProps {
  container: HTMLElement; // container onde será adicionado o elemento day
  text: string; // texto principal
  extraText?: string; // texto secundário
  active?: boolean; // se o elemento está ativo no início - default = false
  classes?: string[]; // classes css aplicadas ao elemento div
}

export interface IDayConstructor {
  new (props: DayProps): IDay;
}

export interface IDay {
  active: boolean; // se o elemento está ativo
  readonly destroy: () => void; // destroi todos os elementos

  onClick?: () => void; // evento disparado ao clicar no elemento
}
`}</code></pre>
    <br />
    <br />
    <Playground __position={2} __code={'() => {\n  const [days, setDays] = useState()\n  //\n  const containerDays = useRef()\n  //\n  useEffect(() => {\n    if (containerDays.current && !days) {\n      const d = new Day({\n        container: containerDays.current,\n        text: \'01\',\n        classes: [\'tw-w-24\'],\n        extraText: \'id: 12345\',\n      })\n      const d1 = new Day({\n        container: containerDays.current,\n        text: \'02\',\n        classes: [\'tw-w-24\'],\n      })\n      const d2 = new Day({\n        container: containerDays.current,\n        text: \'03\',\n        classes: [\'tw-w-24\'],\n        active: true,\n        extraText: \'ID: 4321\',\n      })\n      //\n      setDays([d, d1, d2])\n    }\n    //\n    return () => {\n      if (days) {\n        days.forEach(d => {\n          d.destroy()\n        })\n      }\n    }\n  }, [containerDays.current, days])\n  //\n  return (\n    <div className=\"tw-flex tw-w-full\">\n      <div ref={containerDays}></div>\n    </div>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      useState,
      useRef,
      useEffect,
      Playground,
      Day,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const [days, setDays] = useState(); //

        const containerDays = useRef(); //

        useEffect(() => {
          if (containerDays.current && !days) {
            const d = new Day({
              container: containerDays.current,
              text: '01',
              classes: ['tw-w-24'],
              extraText: 'id: 12345'
            });
            const d1 = new Day({
              container: containerDays.current,
              text: '02',
              classes: ['tw-w-24']
            });
            const d2 = new Day({
              container: containerDays.current,
              text: '03',
              classes: ['tw-w-24'],
              active: true,
              extraText: 'ID: 4321'
            }); //

            setDays([d, d1, d2]);
          } //


          return () => {
            if (days) {
              days.forEach(d => {
                d.destroy();
              });
            }
          };
        }, [containerDays.current, days]); //

        return <div className="tw-flex tw-w-full">
        <div ref={containerDays}></div>
      </div>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      